.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.hash{
    height: auto;
    font-size: 1.2rem;
    padding: 1rem;
}
.ml{
    height: auto;
    justify-self: flex-end;
    font-size: 1.1rem;
    padding: 1rem;
}