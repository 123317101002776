@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@600;700;800&family=Signika+Negative:wght@400;600&display=swap');
body {
  margin: 0;
  font-family: 'Prompt', sans-serif;
  font-family: 'Signika Negative', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after{
    box-sizing: border-box;
}
a{
    text-decoration: none;
    color: unset;
}

html, body{
    width: 100vw;
    height: 100vh;
}


body{
    background: black url("images/bg.jpg") no-repeat 100% 0;
    background-size: cover;
    display: flex;
    color: white;
}

#root{
    width: 90vw;
    height: 90vh;
    margin:auto;
}